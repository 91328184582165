<template>
  <b-table-simple class="table" responsive borderless fixed>
    <thead>
      <tr>
        <th class="date-col">
          <Ellipsis>Data vigência inicial</Ellipsis>
        </th>
        <th class="table-col">Tabela</th>
        <th>Atualização</th>
        <th>Edição</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="items.length">
      <tr v-for="(item, index) in items" :key="index">
        <td class="date-col">{{ parseDate(item?.effective_date) }}</td>
        <td class="table-col">{{ showTableName(item?.table_type) }}</td>
        <td>{{ parseDate(item?.updated_at) }}</td>
        <td>{{ item?.edition }}</td>
        <td>
          <toggle-button
            class="autoFillBtn"
            :value="!!item.active"
            :sync="true"
            :height="24"
            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
            @change="updateStatus(item?.table_type, item)"
          />
        </td>
        <td>
          <div class="actions">
            <Pencil 
              class="icon" 
              v-b-tooltip.hover title="Editar"
              :data-id="'editar-tabela-' + item.table_type"
              @click="goToTablePage(item)" 
            />
          
            <Trash 
              v-b-tooltip.hover title="Excluir"
              class="icon trash" 
              :data-id="'excluir-tabela-' + item.table_type"
              @click="deleteModal(item)"
            />
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-if="!items.length">
      <tr>
        <td colspan="5" class="text-center p-5">
          <p class="mt-5 mb-5">
            Importe a tabela Simpro e/ou Brasindice para configurar um novo padrão no sistema
          </p>
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr></tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import { parseDate } from '@/utils/dateHelper'
import api from '../../api';
export default {
  name: 'MatMedTable',
  components: {
    Pencil: () => import('@/assets/icons/edit.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis.vue'),
  },
  props: {
    clinic: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    parseDate,
    goToTablePage(table) {
      this.$router.push(`/faturamento-tiss/matmed/tabela/${table.table_type}/${table.id}`)
    },
    showTableName(type) {
      return `Tabela ${this.capitalized(type)}`;
    },
    capitalized(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    async updateStatus(tableType, item) { 
      const isLoading = this.$loading.show()
      try {
        const index = this.items.findIndex(i => i.id === item.id)
        this.items[index].active = !item.active
        await api.updateMaterialMedicines(this.clinic.id, tableType, this.items[index])
        this.$toast.success('Tabela atualizada com sucesso!')
      } catch (error) {
        this.$toast.error('Houve um erro ao atualizar a tabela, contate o suporte se o erro persistir.')
      } finally {
        isLoading.hide()
      }
    },
    async deleteTable(id) { 
      const isLoading = this.$loading.show()

      try {
        await api.deleteMaterialMedicinesTable(id)
        this.$toast.success(`Tabela excluída com sucesso!`)
        this.$emit('loadTable')
      } catch (error) {
        this.$toast.error('Houve um erro ao excluir a tabela, contate o suporte se o erro persistir.')
      } finally {
        isLoading.hide()
      }
    },
    deleteModal(table) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          <p>Tem certeza que deseja remover a <b>${this.showTableName(table.table_type)}</b>?</p>
          <p>Essa ação não poderá ser desfeita.</p>
        </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.deleteTable(table.id)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/table.scss';
  .date-col {
    width: 150px !important;
  }
  .table-col {
    min-width: 300px;
  }
  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
</style>
